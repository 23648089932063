import React from 'react';
import LandingPage from '../LandingPage/LandingPage'

type FourOFourProps = {
    children: React.ReactNode;
}

const FourOFour = (props:FourOFourProps) => {
  return (
  <LandingPage>
    <h1>404: Route not found</h1>
    {props.children}
  </LandingPage>
  );
}

export default FourOFour;