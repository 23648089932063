import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { getEnvironmentByHostname, shopSiteLoginPaths } from "../../utils/getEnvironmentByHostname";
import Login from '../Login/Login';
import LandingPage from '../LandingPage/LandingPage'
import FourOFour from "../FourOFour/FourOFour";
import AccessDenied from "../AccessDenied/AccessDenied";
import 'url-search-params-polyfill';

declare global {
  interface Window {
      NREUM: {
        setCustomAttribute: (arg0: string, arg1: string) => void,
        addPageAction: (arg0: string) => void
      }
  }
}

export const env = getEnvironmentByHostname(window.location.hostname);

// console.log(env);

export default () => {
  // console.log(`env.oidc.issuer: ${env.oidc.issuer}`);
  // console.log(`env.oidc.clientId: ${env.oidc.clientId}`);
  // console.log(`env.oidc.redirectUri: ${env.oidc.redirectUri}`);
  // console.log(`env.oidc.scopes: ${env.oidc.scopes}`);

  const query = new URLSearchParams(window.location.search);
  const error = query.get('error');
  const errorDescription = query.get('error_description')

  if (error==="access_denied" && errorDescription){
    return <AccessDenied error={error} errorDescription={errorDescription}/>
  } 

  return (
    <Router>
      <Security
        issuer={env.oidc.issuer}
        client_id={env.oidc.clientId}
        redirect_uri={env.oidc.redirectUri}
        scopes={env.oidc.scopes}
        pkce={true}
      >
        <Switch>
          <Route path={['/', '/index.html', '/logout',...shopSiteLoginPaths]} exact={true} render={() => <Login urls={env.urls} />}/>
          <Route path='/implicit/callback' render={() => <LoginCallback errorComponent={LandingPage}/>} />
          <Route component={FourOFour} />
        </Switch>
      </Security>
    </Router>
  );
};