import { shopSitePathCurrent, validReferrerHosts } from "./getEnvironmentByHostname";

export const getShopSiteCookie = () => {
  return sessionStorage['shopSite']
}

export const setShopSiteCookie = () => {

  const validReferrerPath = validReferrerHosts.includes(document.referrer.split('/')[2]);
  const logoutPath = window.location.pathname.includes('logout');
  
  if (validReferrerPath && !logoutPath){
    removeShopSiteCookie(); // remove old site cookie if user came from valid host
  } 
  
  if (shopSitePathCurrent && !sessionStorage['shopSite']){  
    sessionStorage['shopSite'] = shopSitePathCurrent;
  }
}

export const removeShopSiteCookie = () => {
  sessionStorage['shopSite'] && sessionStorage.removeItem("shopSite");
}
