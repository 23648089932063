import React, { CSSProperties } from 'react';
import { Offline } from "react-detect-offline";

export type LandingPageHeaderProps = {
  message?: string | null;
};

export default (props:LandingPageHeaderProps) => {
  return (
    <header style={headerStyle}>
      <div style={headerBarStyle}>
        <p style={headerBarMessageStyle}>NIKE'S EMPLOYEE DIGITAL SHOP</p>
      </div>
      {props.message ? <h1 style={messageBannerStyle}>{props.message}</h1> : null}
      <Offline><h1 style={offlineBannerStyle}>Sorry, no internet</h1></Offline>
    </header>
  );
}

const headerStyle:CSSProperties = {
  margin: "0 0 19.44444px",
}

const headerBarStyle:CSSProperties = {
  backgroundColor: "#171819",
  textAlign: "center",
  position: "relative",
  zIndex: 10,
  boxSizing: "border-box",
  fontSize: "16px",
  color: "#3d4246",
  lineHeight: 1.5,
};

const headerBarMessageStyle:CSSProperties = {
  marginBottom: 0,
  color: "#ffffff",
  display: "block",
  fontSize: "1em",
  fontWeight: 600,
  padding: "10px 55px",
  margin: "0 0",
  textSizeAdjust: "100%",
  boxSizing: "border-box"
};


const bannerStyle:CSSProperties = {
  color: "#fff",
  marginTop: "0",
  textAlign: 'center',
  padding: "0px",
  fontSize: "medium",
  marginBottom: "0px"
}

const messageBannerStyle:CSSProperties = {
  backgroundColor: '#006100',
  ...bannerStyle,
}

const offlineBannerStyle:CSSProperties = {
  backgroundColor: 'rgb(171, 0, 0)',
  ...bannerStyle
}