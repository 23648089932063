import React, { CSSProperties } from 'react';
import { logNewRelicPageAction } from '../../utils/logNewRelicPageAction';
import LandingPage from '../LandingPage/LandingPage'

type AccessDeniedProps = {
    error: string,
    errorDescription: string
}

const AccessDenied = (props:AccessDeniedProps) => {

  const errorDescriptionClientApp = props.errorDescription === "User is not assigned to the client application." ? `User is not assigned.  Please contact: ` : 'Please contact: ';

  logNewRelicPageAction('ceLoginStage', `Access Denied ${props.errorDescription}`);

  const getShopSite:string = sessionStorage['shopSite']?.replace(/\//g, "") || null;
  
  return (
    <LandingPage>
      <h1 style={accessDeniedStyleHeader} >Access Denied</h1>
      <p style={accessDeniedMessageStyle}>{errorDescriptionClientApp} { getShopSite === "sg" 
              ? <a href="mailto:the.van.sg@nike.com">the.van.sg@nike.com</a> 
              : getShopSite === "uk" 
              ? <a href="mailto:the.van.emea@nike.com">the.van.emea@nike.com</a>  
              : <a href="mailto:the.van.1@nike.com">the.van.1@nike.com</a> }
              
      </p>
    </LandingPage>
  );
}

export const accessDeniedStyleHeader:CSSProperties = {
	color: "#ff0000",
	fontSize: "16px",
}

export const accessDeniedMessageStyle:CSSProperties = {
	color: "#ff0000",
	fontSize: "14px",
}

export default AccessDenied;