interface Urls {
  [region: string]: {
    [env: string]: {
    apiUrl:string,
    shopifyUrl:string
    }
  }
}

export const urls:Urls = { 
  // Run local backend service with serverless: serverless offline --httpPost 3001. See Confluence page on setting up serverless offline
  us: {
    local: {
      apiUrl: "http://localhost:3001/dev/multipass",
      shopifyUrl:`https://thevan-dev-store.myshopify.com/`
    },
    dev: { 
      apiUrl: "https://byf1o25kgf.execute-api.us-west-2.amazonaws.com/dev/test",
      shopifyUrl:`https://thevan-dev-store.myshopify.com/`
    },
    qa: { 
      apiUrl: "https://rqyq4yhbkd.execute-api.us-west-2.amazonaws.com/qa/test",
      shopifyUrl:`https://thevan-dev-qa-store.myshopify.com/`
    },
    uat: { 
      apiUrl: "https://qjfwksfs4d.execute-api.us-west-2.amazonaws.com/uat/test",
      shopifyUrl:`https://thevan-dev-qa-store.myshopify.com/`
    },
    prod: {
      apiUrl: "https://qze6ktajv3.execute-api.us-west-2.amazonaws.com/prd/prod",
      shopifyUrl:`https://thevan.nike.com/`
    }
  },
  sg: {
    local: {
      apiUrl: "http://localhost:3001/dev/multipass",
      shopifyUrl:`https://thevan-sg.nike.com`
    },
    dev: {
      apiUrl: "https://byf1o25kgf.execute-api.us-west-2.amazonaws.com/dev/test",
      shopifyUrl:`https://thevan-sg.nike.com`
    },
    qa: { 
      apiUrl: "https://rqyq4yhbkd.execute-api.us-west-2.amazonaws.com/qa/test",
      shopifyUrl:`https://thevan-sg.nike.com`
    },
    uat: { 
      apiUrl: "https://qjfwksfs4d.execute-api.us-west-2.amazonaws.com/uat/test",
      shopifyUrl:`https://thevan-sg.nike.com`
    },
    prod: {
      apiUrl: "https://qze6ktajv3.execute-api.us-west-2.amazonaws.com/prd/prod",
      shopifyUrl:`https://thevan-sg.nike.com`
    }
  },
  uk: {
    local: {
      apiUrl: "http://localhost:3001/dev/multipass",
      shopifyUrl:`https://thevan-uk.nike.com/`
    },
    dev: {
      apiUrl: "https://byf1o25kgf.execute-api.us-west-2.amazonaws.com/dev/test",
      shopifyUrl:`https://thevan-uk.nike.com/`
    },
    qa: { 
      apiUrl: "https://rqyq4yhbkd.execute-api.us-west-2.amazonaws.com/qa/test",
      shopifyUrl:`https://thevan-uk.nike.com/`
    },
    uat: { 
      apiUrl: "https://qjfwksfs4d.execute-api.us-west-2.amazonaws.com/uat/test",
      shopifyUrl:`https://thevan-uk.nike.com/`
    },
    prod: {
      apiUrl: "https://qze6ktajv3.execute-api.us-west-2.amazonaws.com/prd/prod",
      shopifyUrl:`https://thevan-uk.nike.com/`
    }
  }
}