import React, { CSSProperties } from 'react';
import Logo from '../Logo/Logo';


export type LandingPageBodyProps = {
  children?: React.ReactNode;
};

const LandingPageBody = (props: LandingPageBodyProps) => {
  const shopPath = sessionStorage['shopSite']?.replace(/\//g, "") || '';
  // console.log(sessionStorage['shopSite']?.replace(/\//g, "") || '');
	return (
    <main style={containerStyle}>
      <div style={logoContainerStyle}>
        <Logo style={logoStyle} svgWidth={1000} svgHeight={300} imgWidth={1000} imgHeight={"100%"}/> 
      </div>
      <div style={contentStyle}>
        <h1 style={contentStyleHeader}>
          WELCOME TO THE VAN!
        </h1>

        { 
          shopPath === "uk" && (
          <div>
            <p>
              The vAn UK is available exclusively for NIKE inc teammates in the United Kingdom. The vAn UK now integrates with Nike Okta SSO. <strong>Please login below with your Nike email &amp; password.</strong>
            </p>
            <p>
              If you are unable to login, please contact <a href="mailto:the.van.emea@nike.com">the.van.emea@nike.com</a>
            </p>
          </div>)
        }

        <div style={contentWrapperStyle}>
          {props.children}
        </div>
        <div>
          <p>
          At NIKE we expect all employees to use good judgment and follow the <a href="https://zero.nike.com/home/sites/insidethelines/" target="_blank" rel="noopener noreferrer">INSIDE THE LINES</a> policy. Membership in the vAn requires adhering to the ITL Policy and our documented terms &amp; conditions. The following are examples of abuse of these privileges (this list is not all inclusive) and may result in loss of access, and corrective action, up to and including termination:
          </p>
          <ul>
            <li>Giving The vAn login and password to anyone.</li>
            <li>Buying merchandise for other persons and receiving reimbursement via any payment method, including NIKE gift cards.</li>
            <li>The selling or reselling, directly or indirectly, of NIKE merchandise by employees.</li>
            <li>Seeking to gain exceptional access via any means</li>
          </ul>
        </div>
        <p>
          NIKE has the right to investigate and take appropriate corrective action in cases where there is reason to believe a violation or abuse of this policy has occurred. Employee purchases are privileges and a valuable benefit to all NIKE employees. Employees are expected to use good judgment when using these privileges.
        </p>
        <p>
        If you are having issues logging in, please contact: { 
            shopPath === "sg" 
              ? <a href="mailto:the.van.sg@nike.com">the.van.sg@nike.com</a> 
              : shopPath === "uk" ? <a href="mailto:the.van.emea@nike.com">the.van.emea@nike.com</a> 
              : <a href="mailto:the.van.1@nike.com">the.van.1@nike.com</a> 
          }
        </p>
      </div>
    </main>
	);
}

const containerStyle:CSSProperties = {
  display: 'flex', 
  alignItems: 'center', 
  flexDirection:'column'
}

const logoContainerStyle:CSSProperties = {
  backgroundColor:"#fff", 
  width:'100%', 
  height:'160px', 
  maxWidth: "564px"
}

const contentStyle:CSSProperties = {
  maxWidth: "500px",
  textAlign: "left",
  padding: "1em 2em",
  margin: "0 auto 0",
  display: "border-box",
  backgroundColor: "#fff",
}

const contentStyleHeader:CSSProperties = {
  textAlign: "center",
}
    
const contentWrapperStyle:CSSProperties = {
	textAlign: "center",
}

export const logoStyle:CSSProperties = {
  backgroundColor: "#FFF",
  position: "relative",
  marginBottom: "2%",
};

export default LandingPageBody