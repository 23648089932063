import React, { CSSProperties } from "react";

export type ButtonProps = {
	isLoading: boolean;
	children: React.ReactNode;
	onClick: () => void;
	style: CSSProperties;
};
  
const Button = ({isLoading,children, ...props}:ButtonProps) => {
	return (
		<button className="button" {...props} disabled={isLoading}>
			{isLoading ? <div aria-label="Redirecting To The vAn" role="alert">Loading...</div> : children}
		</button>
	);
};

export const buttonStyle:CSSProperties = {
	padding: "10px 18px",
	backgroundColor: "#000",
	userSelect: "none",
	textDecoration: "none",
	textAlign: "center",
	verticalAlign: "middle",
	border: "1px solid transparent",
	borderRadius:" 2px",
	color: "#fff",
	fontWeight: 600,
	letterSpacing: "0.08em",
	whiteSpace: "normal",
	fontSize: "14px",
	display: "inline-block"
}

export default Button;