import React, { CSSProperties } from 'react';
import Background from '../../TheVan.png';
import LandingPageHeader from './LandingPageHeader';
import LandingPageBody from './LandingPageBody';
import {buttonStyle} from '../Button/Button'
import StatusDisplayMultipassError from '../Login/StatusDisplayMultipassError';
import { errorMessageSuggestion } from '../Login/Login';


export type LandingPageProps = {
  error?: {
    errorCauses: [],
    errorCode: string
    errorId: string
    errorLink: string
    errorSummary: string
    message: string
    name: string
  }
  children?: React.ReactNode;
  headerMessage?: string | null;
};

const LandingPage = (props:LandingPageProps) => {
  const errorMessage = props?.error?.name && `Sorry, error: ${props.error.message} occurred. Please try again later.`
  return (
    <div style={componentStyle}>
      <LandingPageHeader message={props.headerMessage}/>
      <LandingPageBody>
        { props?.error && (
          <>
          <StatusDisplayMultipassError>
            <p>
              {errorMessage}
            </p>
            <p>
              {errorMessageSuggestion}
            </p>
          </StatusDisplayMultipassError>
          <a href="/" style={{...buttonStyle, cursor:'pointer'}}>Continue to Login</a>
          </>
        )}
        {props.children}
      </LandingPageBody>
    </div>
  );
}

export const componentStyle:CSSProperties = {
  height: "-webkit-fill-available",
  display: 'box',
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}

export default LandingPage