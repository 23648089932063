
import React, { CSSProperties } from 'react';
import './Loading.css';

type LoadingProps = {
  children?: React.ReactNode
  styles?: CSSProperties
}
const Loading = (props:LoadingProps) => {
  return (
    <div aria-label="Checking Okta..." style={props.styles}>
      {props.children}
    </div>
  );
}

export default Loading