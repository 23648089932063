import React from 'react';

type ErrorProps = {
  children?: React.ReactNode
}
const StatusDisplayMultipassError = (props:ErrorProps) => {
  return (
    <div style={{color:'rgb(171, 0, 0)'}} role="alert">
      {props.children}
    </div>
  )
}

export default StatusDisplayMultipassError;